<template lang="pug">
    .re-contact-research
        h4 Лабораторные показатели
        laboratory-indicators-block(
            v-model="laboratoryIndicators"
            ref="laboratoryIndicators"
            is-re-contact
        )

        h4 Провокационные тесты
        provocative-tests-block(
            v-model="provocativeTests"
            ref="provocativeTests"
            is-re-contact
        )

        .row.br

        h4 Функциональные тесты
        functional-tests-block(
            v-model="functionalTests"
            ref="functionalTests"
            is-re-contact
        )

        .row.br

        h4 Специфические обследования
        specific-examinations-block(
            v-model="specificExaminations"
            ref="specificExaminations"
            is-re-contact
        )

        .row.br

        h4 КТ и рентген
        special-procedures-block(
            v-model="specialProcedures"
            ref="specialProcedures"
            is-re-contact
        )

        .row.br

        h4 Бронхоальвеолярый лаваж
        lavages-block(
            v-model="lavages"
            ref="lavages"
            is-re-contact
        )

        .row.sticky-buttons-block
            // app-button(large @click="saveAndContinue") Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения
</template>

<script>
import LaboratoryIndicatorsBlock from '@/components/pages/patients/blocks/research/LaboratoryIndicatorsBlock';
import ProvocativeTestsBlock from '@/components/pages/patients/blocks/research/ProvocativeTestsBlock';
import FunctionalTestsBlock from '@/components/pages/patients/blocks/research/FunctionalTestsBlock';
import SpecificExaminationsBlock from '@/components/pages/patients/blocks/research/SpecificExaminationsBlock';
import SpecialProceduresBlock from '@/components/pages/patients/blocks/research/SpecialProceduresBlock';
import LavagesBlock from '@/components/pages/patients/blocks/research/LavagesBlock';
import saving from '@/mixins/saving';

export default {
    name: 're-contact-research',

    components: {
        LaboratoryIndicatorsBlock,
        ProvocativeTestsBlock,
        FunctionalTestsBlock,
        SpecificExaminationsBlock,
        SpecialProceduresBlock,
        LavagesBlock,
    },

    mixins: [saving],

    data: () => ({
        laboratoryIndicators: [],
        provocativeTests: [],
        functionalTests: [],
        specificExaminations: [],
        specialProcedures: [],
        lavages: [],
    }),

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            const lastData = this.lastData;
            this.laboratoryIndicators = this.$refs.laboratoryIndicators.getFormattedSavedFields(lastData.laboratory_indicators);
            this.provocativeTests = this.$refs.provocativeTests.getFormattedSavedFields(lastData.provocative_tests);
            this.functionalTests = this.$refs.functionalTests.getFormattedSavedFields(lastData.functional_tests);
            this.specificExaminations = this.$refs.specificExaminations.getFormattedSavedFields(lastData.specific_examinations);
            this.specialProcedures = this.$refs.specialProcedures.getFormattedSavedFields(lastData.special_procedures);
            this.lavages = this.$refs.lavages.getFormattedSavedFields(lastData.lavages);
        }
    },

    methods: {
        getFormFields() {
            return {
                laboratory_indicators: this.$refs.laboratoryIndicators.getFieldsForSave(),
                provocative_tests: this.$refs.provocativeTests.getFieldsForSave(),
                functional_tests: this.$refs.functionalTests.getFieldsForSave(),
                specific_examinations: this.$refs.specificExaminations.getFieldsForSave(),
                special_procedures: this.$refs.specialProcedures.getFieldsForSave(),
                lavages: this.$refs.lavages.getFieldsForSave(),
            };
        },

        checkValid() {
            if (this.$refs.laboratoryIndicators.$v.$invalid
                || this.$refs.provocativeTests.$v.$invalid
                || this.$refs.functionalTests.$v.$invalid
                || this.$refs.specificExaminations.$v.$invalid
                || this.$refs.specialProcedures.$v.$invalid
                || this.$refs.lavages.$v.$invalid) {
                this.$refs.laboratoryIndicators.$v.$touch();
                this.$refs.provocativeTests.$v.$touch();
                this.$refs.functionalTests.$v.$touch();
                this.$refs.specificExaminations.$v.$touch();
                this.$refs.specialProcedures.$v.$touch();
                this.$refs.lavages.$v.$touch();
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.re-contact-research {
    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }
    }
}
</style>
